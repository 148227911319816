@import "base";
@import "_includes/bootstrap";
@import "_includes/mixins";

label.gfield_label {
  display: none!important;
}
input{
  border: 0px;
  padding-left: 10px!important;
}
textarea{
  border: 0px;
  padding-left: 10px!important;
}
input#gform_submit_button_1 {
  border: 0px;
  float: right;
  padding: 10px 15px 10px 15px;
  font-weight: 600;
  color: #ffffff;
  background-color: #16608a;
}
.gform_wrapper .gform_footer {
  padding: 0px;
  margin: 15px 0 0 0;
  clear: both;
  width: 100%;
}